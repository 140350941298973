<script>
  import { fade } from "svelte/transition";

  let yScroll;

  function goToTop() {
    window.scrollTo(0, 0)
  }
</script>

<svelte:window bind:scrollY={yScroll} />

{#if yScroll >= 1000}
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <span class="go-to-top" transition:fade on:click={goToTop}></span>
{/if}