<script>
  import { fade } from "svelte/transition";
  import { project, postData, translations, orgOperators } from "$lib/services/store";
  import Icons from "$lib/main/components/Icons.svelte";
  import Terms from '$lib/admin/components/blocks/helpers/Terms.svelte';

  let yScroll;
  let operator = null;

  if ($postData.parent_id == $project.data.categoryPages.reviews.id && Array.isArray($orgOperators)) {
    operator = $orgOperators.find(x => x.name_lower == $postData.slug.split("/").pop())
  }
</script>

<svelte:window bind:scrollY={yScroll} />

{#if yScroll >= 100}
  <div
    transition:fade
    class="mobile-nav"
    class:operator-cta={$postData.parent_id == $project.data.categoryPages.reviews.id}>
    {#if ($postData.parent_id == $project.data.categoryPages.reviews.id) && operator}
      <a class="button" href={"/go/" + operator.name_lower}>{ $translations["To"][$project.settings.lang] + " " + operator.name}</a>
      <i><Terms /></i>
    {:else}
      <ul>
        {#each $project.menu.mobileMenu as menuItem}
          <li>
            <a href={menuItem.path}>
              <Icons name={menuItem.icon} strokeWidth="2" width="24" height="24" />
              <span>{menuItem.name}</span>
            </a>
          </li>
        {/each}
      </ul>
    {/if}
  </div>
{/if}