<script>
  import { fade } from 'svelte/transition'

  let acceptedCookies = false
</script>

<!--
  TODO: create functionality for cookies - be able to set cookies for accepted cookies
-->
{#if !acceptedCookies && 2 < 1}
  <div transition:fade class="cookies">
    <span class="cookies-text">Denna webbplats använder sig av cookies för att förbättra upplevelsen för dig som användare <a href="https://www.casinofeber.se/sekretesspolicy" rel="noopener noreferrer" target="_blank">Läs mer</a></span>
    <button on:click={() => (acceptedCookies = !acceptedCookies)}>Jag förstår</button>
  </div>
{/if}

<style>
  .cookies {
    position: sticky;
    bottom: 1rem;
    padding: 1rem;
    margin: 1rem;
    background: var(--clr-pri-alt);
    border-radius: var(--bd-rad);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .cookies-text {
    font-size: 0.875rem;
    font-weight: 300;
    color: var(--clr-pri-txt);
  }

  a {
    font-weight: 500;
    color: var(--clr-pri-txt);
    opacity: 0.85
  }
  a:hover {
    opacity: 1;
    text-decoration: none;
  }

  button {
    background: var(--clr-cta);
    display: block;
    border-radius: var(--bd-rad-sm);
    font-size: 0.875rem;
    font-weight: 700;
    padding: .6em 4.8em;
    text-align: center;
    white-space: nowrap;
    border: 0;
    color: var(--clr-pri-txt);
  }
  button:hover {
    background: var(--clr-cta-hvr);
    cursor: pointer;
  }
</style>