<script>
  import { onMount } from 'svelte'
  import { invalidateAll } from '$app/navigation'
  import { supabase } from "$lib/services/db"
  import { project, postData, postUsers } from "$lib/services/store";
  import { organization, article } from "$lib/data/mappers/structured-data"

  import "../../app.postcss";

  import Sidebar from "$lib/main/Sidebar.svelte";
  import Nav from '$lib/main/Nav.svelte'
  import MobileNav from '$lib/main/MobileNav.svelte'
  import Footer from '$lib/main/Footer.svelte'
  import GoToTopButton from '$lib/main/GoToTopButton.svelte'
  import Cookies from '$lib/main/Cookies.svelte'
  import Announcement from '$lib/main/components/Announcement.svelte'

  onMount(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange(() => {
      invalidateAll();
    });
    return () => {
      subscription.unsubscribe();
    }
  })

  export let data;
  const { centralData } = data;

  project.set(data.project)
</script>

<svelte:head>
  <!-- TODO:
    - remove this when a project goes live
    - create a boolean in settings to be able to put this on/off
  -->
  <meta name="robots" content="noindex, noarchive">

  <link rel="icon" href={$project.data?.imageFolders?.assets + $project.name_lower + "-favicon.png?w=50&h=50"} />

  {@html organization($project)}
  {@html article($postData, $postUsers, $project)}
</svelte:head>

<div class={"body t-" + $project.settings.theme + " p-" + $project.key}>
  {#if $project.settings.announcement?.status == 'active'}
    <Announcement type={$project.settings.announcement?.type} html={$project.settings.announcement?.html} />
  {/if}

  <Nav />

  <main class:sidebar={$project.settings?.sidebar}>
    <slot />

    {#if $project.settings?.sidebar}
      <Sidebar />
    {/if}
  </main>

  <!-- TODO:
    what is best: to pass the type from layout (like this) or to pass it directly in the footer component?
    it feels cleaner to move it into the component, but then it will be rendered on client-side? Unless we move that to +page.server.js (if we can?)
    Note that with current setup moving the type setting under Footer component makes the component render the front-end footer in the back-end (should be a simple way to fix though)
  -->
  <Footer address={ centralData.data.address }  />
</div>

{#if $project.menu.mobileMenu}
  <MobileNav />
{/if}

<GoToTopButton />

<Cookies />